@charset "UTF-8";
/*vsechny soubory - obsahem je absolutni zaklad vyuzity jak na titulce, tak podstrankach, nezalinkovano do FTB*/
/*! ECCO SASS knihovna
    @version: 1.0.4 */
/*****************************************
* ECCO - GLOBAL VARIABLES
*****************************************/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*****************************************
* ECCO - PLACEHOLDERS
*****************************************/
#kalakci .calcells, form, .vol-sdileni, .akce-podle-data .inline {
  margin: 0;
  padding: 0;
}

.vol-sdileni, .akce-podle-data .inline {
  list-style-type: none;
}

.btn {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent none;
  box-shadow: none;
}
.btn:focus {
  box-shadow: none;
}

.format,
.btn {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

ul#volsocialhub::after {
  content: "";
  display: table;
  font-size: 0;
  clear: both;
}

/*****************************************
* ECCO - FLEXBOX
*****************************************/
/*pro editor a vsechny stranky s editorem, nemelo by byt zalinkovane k prihlasovacim formularum a jinde, kde neni editor. pokud neni na strance vyplneny .editor, nemelo by byt v needitacnim rezimu pripojeno*/
.small {
  font-size: 0.75rem;
}

.big {
  font-size: 1.125rem;
}

.extrabig {
  font-size: 1.5625rem;
}

#stranka .editor {
  margin-bottom: 25px;
}
.editor table {
  border-spacing: 0;
  border-collapse: collapse;
  max-width: 100%;
}
.editor td,
.editor th {
  text-align: left;
  padding: 8px;
  border: 1px #868686 solid;
  vertical-align: top;
}
.editor td[align=center],
.editor th[align=center] {
  text-align: center;
}
.editor td[align=right],
.editor th[align=right] {
  text-align: right;
}
.editor table[border="0"] td,
.editor table[border="0"] th {
  border: 0;
}
.editor th {
  background-color: #eeeeee;
}
.editor p {
  padding: 0;
  margin: 8px 0 0 0;
}
.editor font[size="5"],
.editor font[size="6"] {
  line-height: normal;
}
.editor img {
  position: relative;
  float: left;
  margin: 4px 15px 4px 0;
}
.editor img.vpravo {
  float: right;
  margin: 4px 0 4px 15px;
}
.editor p.nastred img {
  float: none;
}
.editor .nastred {
  width: auto;
  text-align: center;
}
.editor hr {
  margin: 8px 0;
  width: 100%;
  clear: both;
  display: block;
}
.editor ul,
.editor ol {
  clear: left;
  margin: 10px 0 10px 25px;
  padding: 0;
}
.editor ol {
  margin-left: 30px;
}
.editor li ul {
  margin: 5px 0;
}
.editor li ol {
  margin: 5px 0 5px 10px;
}
.editor li {
  padding: 0;
  margin: 2px 0 2px 15px;
}
.editor blockquote {
  padding: 5px 0;
  margin: 0 0 0 15px;
}
.editor ol li {
  list-style-type: decimal;
}
.editor li ol li {
  list-style-type: lower-alpha;
}
.editor li li ol li {
  list-style-type: lower-roman;
}
.editor ul li {
  list-style-type: square;
}
.editor ul.mbannery {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: center;
}
.editor ul.mbannery li {
  padding: 0;
}
.editor ul.mbannery li,
.editor ul.mbannery li a {
  display: inline-block;
}
.editor ul.mbannery img {
  display: inline;
}
.editor ul.mbannery li,
.editor ul.mbannery img {
  float: none !important;
  vertical-align: middle;
  margin: 7px 3px;
}

body.editor {
  padding: 10px;
}
body.editor p {
  border: 1px blue dashed;
}
body.editor img {
  position: static;
}
body.editor .sf {
  border-bottom: 1px green dashed;
  margin: 3px 0 0 0 !important;
  padding: 0;
  overflow: hidden;
  float: none !important;
  display: block;
  clear: both;
  font-size: 0;
  line-height: 0;
  height: 0;
  max-height: 0;
}

.sfleft {
  clear: left !important;
}

.justify {
  text-align: justify;
}

.malamezera {
  line-height: 5px;
}

.velkamezera {
  line-height: 15px;
}

.yellowbg {
  background-color: yellow !important;
  color: black;
}

.redbg {
  background-color: #f00000 !important;
  color: white;
}

.blue {
  color: blue;
}

.green {
  color: green;
}

.red {
  color: red;
}

.orange {
  color: #ffa500;
}

.yellow {
  color: yellow;
}

.darkgray {
  color: #a9a9a9;
}

.white {
  color: white;
  background-color: silver;
}

.black {
  color: black;
}

.zvyrazneni2 {
  padding: 0 3px;
}

.ftb-ytb-video__wrapper {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  max-width: 100%;
}
.ftb-ytb-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

a,
a:link,
a:visited {
  text-decoration-skip: ink;
}

h5,
h4,
h3,
p {
  margin: 20px 0 5px 0;
  padding: 0;
}

p {
  margin: 8px 0;
}

ul,
ol {
  margin-top: 10px;
  margin-bottom: 10px;
}

li {
  margin-top: 2px;
  margin-bottom: 2px;
}

a.patalogo,
a.patalogo:link,
a.patalogo:visited,
a.patalogo:hover,
a.patalogo:focus,
a.patalogo:active {
  color: #da0500 !important;
  font-weight: bold;
  border-radius: 2px;
  text-decoration: none !important;
  background-color: #ffffff !important;
  padding: 1px 0.26em;
}

.patalogo span {
  color: black !important;
  font-weight: normal;
}
.patalogo span.vis {
  font-weight: bold;
}

hr {
  display: block;
  height: 1px;
  line-height: 1px;
  overflow: hidden;
  margin: 28px 0 4px 0;
  padding: 0;
  border: 0;
}

img {
  border: 0;
  font-weight: normal;
  max-width: 100%;
  height: auto;
}

.vystraha {
  font-weight: bold;
  font-style: normal;
}

.sf {
  float: none !important;
  display: block;
  clear: both;
  font-size: 0;
  line-height: 0;
  height: 0;
  border: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  min-height: 0;
  max-height: 0;
}

#celek .sf *,
a#hlobsah,
.sf,
#anketa .hlas div {
  color: transparent !important;
}

.btn {
  cursor: pointer;
}

.cist {
  position: absolute;
  left: -10000px;
  top: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.skryt {
  display: none;
}

.fulltex {
  background-color: #333333;
  color: white;
  font-weight: bold;
  padding: 0 4px;
}

ul#volsocialhub {
  display: block;
  margin: 0 0 7px 0;
  padding: 17px 0 0 0;
}
ul#volsocialhub li {
  margin: 0 20px -7px 0;
  padding: 0;
  float: left;
  white-space: nowrap;
  display: block;
  list-style-type: none;
}
ul#volsocialhub li a {
  display: block;
  padding: 8px 0 8px 30px;
}

.facebook {
  background: transparent url(/ASPINCLUDE/vismoWeb5/html/images/ikony/svg/share_fb.svg) no-repeat left center;
}

.twitter {
  background: transparent url(/ASPINCLUDE/vismoWeb5/html/images/ikony/svg/share_tw.svg) no-repeat left center;
}

li.zaslat {
  background: transparent url(/ASPINCLUDE/vismoWeb5/html/images/ikony/svg/share_mail.svg) no-repeat left center;
}

.google {
  background: transparent url(/ASPINCLUDE/vismoWeb5/html/images/ikony/svg/share_g.svg) no-repeat left center;
}

/*vsechny kalendare na webu*/
#kalakci {
  min-width: 7em;
  width: auto;
}

div.mainheading {
  margin: 6px 8px 0 10px;
}
div.mainheading input {
  width: auto;
  margin: 0;
}

.calcells,
.caldayheading {
  width: 95%;
  margin: 0;
}

.calendar .calcells td {
  padding: 7px 0;
}
.calendar .calcells td.wkhead {
  background-color: transparent;
}

.caldayheading {
  margin-bottom: -5px;
}

.calcells td,
.caldayheading td {
  font-weight: normal;
  width: 10%;
  text-align: center;
  padding: 2px;
}

.calcells thead th {
  text-align: center;
}

.calcells td.hover,
.calcells a {
  cursor: pointer;
}
.calcells td.curdate {
  padding: 0 !important;
}

#kalakci {
  border: 0;
}
#kalakci td > a {
  display: block;
  font-weight: bold;
}
#kalakci .calcells {
  width: 100%;
}
#kalakci .calcells td {
  width: 14.2857142857%;
}
#kalakci .calcells tr th.cist:first-child {
  display: none;
}
#kalakci a:hover,
#kalakci a:focus,
#kalakci a:active {
  text-decoration: underline;
}

.akcekal,
#kalendar {
  position: relative;
  z-index: 1;
  overflow: visible !important;
}

/*******************************
* VYSKAKOVACÍ SEZNAM AKCÍ
*******************************/
.floatingBubbleElm {
  border: 1px solid #FFFFFF;
  position: absolute;
  display: none;
  left: auto;
  z-index: 50;
  text-align: left;
  height: auto !important;
  margin-top: 0;
  width: 13em;
  padding: 4px;
}
.floatingBubbleElm div.vypisakci {
  overflow: auto;
}
.floatingBubbleElm ul {
  margin: 0 !important;
  padding: 5px 0 8px 0 !important;
  width: auto;
  height: auto !important;
  background-color: #ffffff !important;
}
.floatingBubbleElm li {
  padding: 0 4%;
  margin: 0 !important;
  display: block;
  list-style-type: none;
  border-top: 1px #cecece solid;
  color: #000000 !important;
  width: auto;
}
.floatingBubbleElm li:first-child,
.floatingBubbleElm li.firstDayAction {
  border: 0;
  padding-top: 5px;
}

#kalakci .floatingBubbleElm a {
  font-weight: normal !important;
  display: block;
  padding: 8px 0 !important;
  background: none !important;
  position: static !important;
  text-decoration: none !important;
  color: #5F5F5F !important;
}

#celek #kalakci .floatingBubbleElm p {
  background-color: transparent !important;
  text-align: center;
  font-weight: bold;
  background: transparent none !important;
  margin: 0;
  padding: 3px 5px;
}

.floatingBubbleIsVisible {
  left: auto;
  display: block;
}

.floatingBubbleIsInvisible {
  display: none;
}

@media screen {
  /*barvy*/
  #voliviewer-counter, #voliviewer-counter *, #voliviewer_bottom_toolbar, #voliviewer_bottom_toolbar *, #infoPanel, #infoPanel * {
    color: #9E9E9E !important;
  }
  #infoPanel, #navigationPanel, #imgBgPanel {
    background-color: #000000;
  }
  #navigationPanel, #voliviewer_bottom_toolbar {
    font-size: 92%;
  }
  #initImage_Info {
    font-size: 100%;
    display: block;
    position: absolute;
    width: 100%;
    top: 45px !important;
    left: 0 !important;
    text-align: center;
  }
  #navigationPanel, #navigationPanel *, #voliviewer_bottom_toolbar, #voliviewer_bottom_toolbar * {
    font-weight: normal;
  }
  #imgBgPanel {
    z-index: 100;
    position: absolute;
    left: 0;
  }
  #imgPanel {
    background-color: transparent;
    position: absolute;
    z-index: 110;
  }
  #navigationPanel {
    position: absolute;
    z-index: 100;
    cursor: default;
  }
  #infoPanel {
    position: absolute;
    height: auto;
    z-index: 110;
    cursor: default;
    text-align: left;
  }
  #overImgLayout {
    position: absolute;
    z-index: 150;
    background-color: #000000;
  }
  .voliviewer-arrowLeft, .voliviewer-arrowRight {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: #000000;
    display: block;
  }
  #voliviewer_top_toolbar {
    position: absolute;
    width: inherit;
    float: right;
    margin-top: 0px;
    left: 0;
    width: 100%;
    z-index: 30;
    font-size: 71%;
  }
  #voliviewer_top_toolbar a {
    font-weight: normal;
    color: #D0D0D0;
    text-decoration: none;
  }
  #voliviewer_top_center_toolbar {
    padding: 10px 0px 0px 0px;
  }
  #voliviewer-counter {
    position: absolute;
    left: 0;
    margin: 2px 0px 0px 10px;
    height: 20px;
  }
  #voliviewer_buttons_toolbar {
    position: relative;
    float: right;
  }
  #voliviewer_buttons_toolbar a {
    position: relative;
  }
  .voliviewer-buttons {
    width: 125px;
    margin: 0 auto;
  }
  .voliviewer-buttons a,
  .voliviewer-buttons a:link,
  .voliviewer-buttons a:visited,
  .voliviewer-buttons a:hover,
  .voliviewer-buttons a:focus,
  .voliviewer-buttons a:active {
    text-decoration: none !important;
  }
  .voliviewer-buttons img {
    float: left;
    margin-right: 7px;
  }
  /*_KN_20111201_k STYLY PRO PEKNY SLIDESHOW*/
  #buttonEnd img {
    margin-right: 0;
  }
  .voliviewer-slideshowInfo {
    float: left;
    font-weight: normal;
    padding-right: 0;
    padding-top: 1px;
  }
  .voliviewer-floatInfoPanel {
    background-color: #FFFFFF;
    color: #000000;
    padding: 5px;
  }
  #otherLinksArea {
    float: right;
    padding: 0px;
    float: right;
    position: absolute;
    margin-right: 10px;
    right: 0px;
  }
  #voliviewer_bottom_toolbar {
    height: auto;
    min-height: 22px;
    margin: 5px 0px 0px 0px;
    padding: 2px 10px 8px 10px;
    z-index: 20;
  }
  #voliviewer_bottom_toolbar a {
    font-weight: normal;
    text-decoration: underline;
  }
  .voliviewer-info {
    padding: 10px 10px 0px 10px;
    font-size: 145%;
  }
  #voliviewer-buttons-light {
    float: right;
    margin: 0px 15px 10px 0px;
  }
  #voliviewer_description {
    font-size: 71%;
    padding: 2px 10px 0 10px;
    z-index: 20;
    color: red;
  }
}
#vismo-cookie-wrapper {
  text-align: center;
  position: fixed;
  width: 100%;
  height: 0;
  overflow: visible;
  z-index: 60;
  left: 0;
  right: 0;
  font-size: 14px;
}

#vismo-cookie-panel {
  -webkit-transition: bottom 1s ease-out;
  transition: bottom 1s ease-out;
  position: fixed;
  width: 100%;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  background: #343434;
  color: #ffffff;
}
#vismo-cookie-panel p {
  margin: 0;
  padding: 5px 15px;
  line-height: 1;
}
#vismo-cookie-panel .vismo-cookie-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2;
  padding: 0 14px;
}
#vismo-cookie-panel .vismo-cookie-text a {
  display: inline-block;
  text-decoration: underline;
  color: #ffffff;
}
#vismo-cookie-panel .vismo-cookie-button {
  display: inline-block;
  vertical-align: middle;
}
#vismo-cookie-panel #vismo-cookie-agree-button {
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
  display: inline-block;
  background-color: #e4e4e4;
  text-decoration: none;
  padding: 8px 15px;
  margin: 0;
  border-radius: 3px;
  color: #343434;
}
#vismo-cookie-panel #vismo-cookie-agree-button:hover {
  background-color: #ffffff;
}
#vismo-cookie-panel .vismo-cookie-clear {
  display: block;
  height: 0;
  line-height: 0;
  font-size: 0.1px;
  clear: both;
  width: 100%;
}

.inline li {
  display: inline;
  list-style-type: none;
}
.inline li::before {
  position: relative;
  content: "|";
  margin: 0 10px 0 11px;
}
.inline li:first-child::before, .inline li.skip::before, .inline li.skip + li:not(.skip)::before {
  content: normal;
}

@media screen and (max-width: 799px) {
  #vismo-cookie-panel p {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  #vismo-cookie-panel .vismo-cookie-button {
    margin-top: 10px;
    display: block;
  }
}
.aboEonBanner {
  text-align: center;
  padding: 20px 0 30px 0;
}
.aboEonBanner a {
  display: inline-block;
}

#abo table td {
  text-align: left;
}
#abo table td img {
  max-width: none;
}

script {
  display: none !important;
}

#kalakci .floatingBubbleElm p {
  font-size: 1rem;
}
#kalakci td,
#kalakci th {
  font-size: 0.9375rem;
}

.vzzalozky,
#rejstrik ul.rejstrikpismena {
  font-size: 1.0625rem;
}

#fzapis .fnote,
.popis.dpopis,
.zobrazeno.kontext2,
#prepinac,
.vyhlodkaz {
  font-size: 0.9375rem;
}

.akce-podle-data {
  margin: 0 0 30px 0;
}
.akce-podle-data .inline {
  display: inline;
}

/*************************************************
*	POČASÍ - BLUEBOARD (STŘEDNÍ VELIKOST, DVA DNY)
*************************************************/
[id*=volrecaptcha] {
  text-align: center;
  padding: 15px 0;
  clear: both;
}

[id*=volrecaptcha] > div {
  margin: 0 auto;
}

.volrecaptcha {
  clear: both;
  padding: 15px 0;
}
.volrecaptcha::after {
  content: "";
  height: 0;
  display: table;
  width: 100%;
  clear: both;
}

.volrecaptcha [id*=volrecaptcha] {
  padding: 0;
}

.vol-sdileni {
  margin-top: 35px;
}
.vol-sdileni li {
  display: inline-block;
  margin: 0;
}
.vol-sdileni li * {
  vertical-align: middle;
}
.vol-sdileni li a {
  display: inline-block;
}
.vol-sdileni li span {
  padding-left: 5px;
  padding-right: 1em;
  vertical-align: baseline;
}
.vol-sdileni li.sdilet-facebook img {
  background-color: #0866ff;
  border-radius: 50%;
}
.vol-sdileni li.sdilet-twitter img {
  background-color: #1da1f2;
  border-radius: 50%;
}
.vol-sdileni li.sdilet-x img {
  background-color: #000;
  border-radius: 50%;
}
.vol-sdileni li.sdilet-google img {
  background-color: #db4437;
  border-radius: 50%;
}
.vol-sdileni li.sdilet-mail img {
  background-color: #444444;
  border-radius: 50%;
}
.vol-sdileni li img {
  color: #ffffff;
}
@media screen and (max-width: 399px) {
  .vol-sdileni li img {
    width: 32px;
    height: 32px;
  }
}

table.calendar {
  background-color: #ffffff;
}

/* 11. 4. 2017 - kvůli novému Google Custom Search */
#hl::placeholder {
  color: transparent;
}

/* JakubKo 20170525 */
/***************************************
*	POČASÍ - OPENWEATHERMAP - MAX 5 DNÍ
***************************************/
.owm {
  text-align: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 1rem;
}
.owm span[class*=owm] {
  display: block;
}
.owm .owmIcon {
  padding: 0.5em 0;
}
.owm li {
  display: inline-block;
  padding: 0;
  margin: 0 1em 1em 1em;
}
.owm.owm-1 li .owmDateDayName, .owm.owm-2 li .owmDateDayName, .owm.owm-3 li .owmDateDayName {
  display: none;
}
.owm.owm-4 li .owmDateDay, .owm.owm-5 li .owmDateDay {
  display: none;
}
.owm.owm-1 li:nth-of-type(1) ~ li {
  display: none;
}
.owm.owm-2 li:nth-of-type(2) ~ li {
  display: none;
}
.owm.owm-3 li:nth-of-type(3) ~ li {
  display: none;
}
.owm.owm-4 li:nth-of-type(4) ~ li {
  display: none;
}
.owm.owm-5 li:nth-of-type(5) ~ li {
  display: none;
}
.owm .owmTempMax {
  font-size: 1.1875rem;
}
.owm img {
  width: 46px;
  height: auto;
}

.vzor-e-2016 .wrapper-novinky .dok li > strong {
  padding-bottom: 12px;
}
.vzor-e-2016 .horizontal .dok ul li:last-child .dok-telo {
  padding-bottom: 40px;
}
.vzor-e-2016 #kontakt address,
.vzor-e-2016 #anketa .dotaz {
  line-height: 1.55;
}
.vzor-e-2016 .wrapper.wrapper-uvod {
  margin-bottom: 0;
}
.vzor-e-2016 .wrapper.wrapper-uvod::after {
  content: "";
  padding-bottom: 75px;
  height: 0;
  display: block;
}
.vzor-e-2016 #anketa ul li {
  margin-bottom: 20px;
}
.vzor-e-2016 .titulodkazy li {
  margin: 8px 0;
}
.vzor-e-2016 #obrazek #blender {
  padding-bottom: 30%;
}
@media screen and (min-width: 1281px) {
  .vzor-e-2016 #obrazek #blender {
    padding-bottom: 40%;
  }
}
.vzor-e-2016 #obrazek #blender .prolinani-e-obrazek {
  width: 30%;
  padding-bottom: 27.88%;
}
@media screen and (min-width: 1281px) {
  .vzor-e-2016 #obrazek #blender .prolinani-e-obrazek {
    width: 40%;
    padding-bottom: 37.88%;
  }
}
.vzor-e-2016 .owm li {
  text-align: center;
}
.vzor-e-2016 .owm li:first-child {
  margin-left: 0;
}

/** JakubKo 20180508 - GDPR */
.titulformular .bezny.gdprsouhlas {
  margin-top: 0.85em;
  margin-bottom: 1em;
  padding-bottom: 0;
}

#souhlasform::after,
#odvolanisouhlasuform::after {
  content: "";
  height: 0;
  display: table;
  clear: both;
}

.feditace p.gdprsouhlas {
  margin-bottom: 1.65em;
}

#stranka [action*="/aa/"] .fbtn {
  text-align: right;
}

/** JakubKo 20180508 - /GDPR */
/************************
PŘEPISY
************************/
@supports (-ms-ime-align: auto) {
  [class*=individualy-] #kalakci .calcells td::before,
  .vzor-id-2017 #kalakci .calcells td::before,
  .vzor-f-2018 #kalakci .calcells td::before {
    top: 1em;
  }
  @media screen and (min-width: 451px) {
    [class*=individualy-] #kalakci .calcells td::before,
    .vzor-id-2017 #kalakci .calcells td::before,
    .vzor-f-2018 #kalakci .calcells td::before {
      top: 1.25em;
    }
  }
}
[class*=individualy-] h5,
.vzor-id-2017 h5,
.vzor-f-2018 h5 {
  margin-bottom: 0.4em;
}

.vzor-f-2018 h5,
.vzor-f-2018 h6 {
  font-family: "MagraWeb";
}

.vzor-e-2016 h5,
.vzor-e-2016 h6 {
  margin-bottom: 0.4em;
  font-family: "TekoWeb";
}
.vzor-e-2016 h5 {
  font-size: 1.165em;
}
.vzor-e-2016 .dok .dok-nazev {
  display: block;
}

#fprihl.fkont.nizky .fvpravo p {
  text-align: right;
}